<template>
  <div class="container-fluid">
    <div v-if="globalOrganization?.id" class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <router-link
              :to="{ name: 'List PricingRules' }"
              v-slot="{ href, navigate }"
              custom
            >
              <a :href="href" @click="navigate">
                <i class="far fa-long-arrow-left"></i>
              </a>
            </router-link>
            <h3>{{ $t("COMMON.PRICING_RULES") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <router-link :to="{ name: 'List PricingRules' }">
                  <img src="/img/kw-home.svg" alt="icon" />
                  <span>{{ $t("BOOKINGS.BOOKING_LIST") }}</span>
                </router-link>
              </li>
              <li>
                <span>{{ $t("COMMON.PRICING_RULES") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right"></div>
      </div>

      <calendar-table
        @onCreatePricing="openPricingCreateModal"
        :key="renderKey * 100"
      />
    </div>
    <div v-else class="page-wrapper orga-select">
      <img src="../../../../../public/img/kw-fire.svg" alt="icon" />
      <p>{{ $t("COMMON.PLEASE_SELECT_ORGANIZATION") }}</p>
    </div>
  </div>
</template>
<script>
import {
  Button,
  Link,
  PageHeader,
  Breadcrumb,
  BreadcrumbItem,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
} from "@/constants/common";
import { mapGetters } from "vuex";
import CalendarTable from "./partials/CalendarTable.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";

export default {
  layout: "DashboardLayout",

  components: {
    // WrapperExpandButton,
    CalendarTable,

    [Button.name]: Button,
    [PageHeader.name]: PageHeader,
    [Link.name]: Link,
    [BreadcrumbItem.name]: BreadcrumbItem,
    [Breadcrumb.name]: Breadcrumb,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },

  watch: {},

  data() {
    const pricingId = this.$route.query.id;
    const spotId = this.$route.query.spotId;
    const action = this.$route.query.action;
    let isViewPricingModalOpened = false;
    let isEditPricingModalOpened = false;
    let isAddPricingModalOpened = false;
    let openPricing = null;
    if (pricingId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewPricingModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditPricingModalOpened = true;
      }
      openPricing = { id: pricingId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddPricingModalOpened = true;
    }
    return {
      isViewPricingModalOpened: isViewPricingModalOpened,
      isEditPricingModalOpened: isEditPricingModalOpened,
      isAddPricingModalOpened: isAddPricingModalOpened,
      openPricing: openPricing,
      selectedSpotId: spotId,
      renderKey: 1,
    };
  },

  methods: {
    openPricingCreateModal(spotId) {
      this.selectedSpotId = spotId;
      this.closePricingModal();
      this.isAddPricingModalOpened = true;
      console.log("openPricingCreateModal", spotId);

      history.pushState(
        { spot: spotId },
        null,
        this.$router.resolve({
          name: "View Calendar",
          query: { spotId: spotId, action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openPricingModal(pricing, reRender = false) {
      this.closePricingModal();
      this.openPricing = pricing;
      this.isViewPricingModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "View Calendar",
          query: { id: this.openPricing.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    closePricingModal() {
      this.isAddPricingModalOpened = false;
      this.isViewPricingModalOpened = false;
      this.isEditPricingModalOpened = false;
      this.openPricing = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "View Calendar",
          query: {},
        }).href
      );
    },
  },
};
</script>
