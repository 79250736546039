<template>
  <div class="bryntum-calendar-wrapper">
    <div class="dates-range">
      <div class="dates-range-left">
        <base-button class="colored" @click="goTo(-30)">
          <i class="far fa-angle-double-left"></i> 30
        </base-button>
        <base-button class="outline" @click="goTo(-7)">
          <i class="far fa-angle-double-left"></i> 7
        </base-button>
      </div>
      <div class="dates-range-center">
        <base-button class="outline" @click="today">
          <i class="far fa-angle-double-right"></i>
          {{ $t("COMMON.TODAY") }}
          <i class="far fa-angle-double-left"></i>
        </base-button>
      </div>
      <div class="dates-range-right">
        <base-button class="outline" @click="goTo(7)">
          7 <i class="far fa-angle-double-right"></i>
        </base-button>
        <base-button class="colored" @click="goTo(30)">
          30 <i class="far fa-angle-double-right"></i>
        </base-button>
      </div>
    </div>

    <bryntum-scheduler
      :key="renderKey"
      event-style="rounded"
      v-bind="schedulerConfig"
      :resources="resources"
      :resourceTimeRanges="resourceTimeRanges"
      :start-date="startDate"
      :end-date="endDate"
    />
  </div>
</template>

<script>
import { BryntumScheduler } from "@bryntum/scheduler-vue";
import { StringHelper } from "@bryntum/scheduler";
import { DateHelper } from "@bryntum/scheduler";
import { debounce } from "lodash";

export default {
  name: "pricing-calendar-component",

  components: { BryntumScheduler },

  props: {
    pricingRules: {
      type: Array,
      required: true,
    },
    startDateValue: {
      type: [Date, String],
      required: true,
    },
    endDateValue: {
      type: [Date, String],
      required: true,
    },
  },

  data() {
    const startDate = this.startDateValue;
    const endDate = this.endDateValue;

    return {
      renderKey: 0,
      startDate,
      endDate,
      schedulerConfig: {
        viewPreset: "dayAndMonth",
        rowHeight: 60,
        barMargin: 5,
        tickSize: 70,
        zoomOnTimeAxisDoubleClick: false,
        zoomOnMouseWheel: false,
        cellEditFeature: false,
        eventDragCreateFeature: false,
        eventEditFeature: false,
        scheduleTooltipFeature: false,
        timeRangesFeature: true,
        resourceTimeRangesFeature: {
          enableMouseEvents: true,
          showCurrentTimeLine: true,
          showHeaderElements: true,
          enableResizing: true,
        },
        columns: [
          {
            text: this.$t("COMMON.PRICING_RULES"),
            field: "name",
            width: 280,
            htmlEncode: false,
            renderer: ({ value, record }) =>
              StringHelper.xss`
                <div class=""> 
                  <span>
                    ${StringHelper.encodeHtml(value)}
                  </span>
                </div>
              `,
          },
        ],
        resourceTimeRangeRenderer({ resourceTimeRangeRecord, renderData }) {
          renderData.style = {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          };

          return {
            tag: "div",
            html: `<strong>${resourceTimeRangeRecord.name}</strong>`,
          };
        },
      },
    };
  },

  watch: {
    startDate() {
      this.debouncedEmitDateRangeChanged();
    },
    endDate() {
      this.debouncedEmitDateRangeChanged();
    },
    startDateValue(value) {
      this.startDate = value;
    },
    endDateValue(value) {
      this.endDate = value;
    },
    pricingRules(value) {
      this.renderKey++;
    },
  },
  computed: {
    resources: function () {
      const resourcesList = [];
      this.pricingRules.forEach((pricing) => {
        let value = `${this.$formatCurrency(
          pricing.value
        )} / ${this.$formatCurrency(pricing.weekend_value)}`;
        if (pricing.derived) {
          if (pricing.pricing_type === "percent") {
            value = `${pricing.variation} ${pricing.value}/${pricing.weekend_value}%`;
          } else if (pricing.pricing_type === "amount") {
            value = `${pricing.variation} ${$formatCurrency(
              pricing.value
            )}/${$formatCurrency(pricing.weekend_value)}`;
          }
        }
        resourcesList.push({
          id: pricing.id,
          name: `${pricing.name} ( ${value} )`,
        });
      });
      return resourcesList;
    },
    resourceTimeRanges: function () {
      const resourceTimeRanges = this.pricingRules.flatMap(
        (pricing, pricingIndex) => {
          if (pricing.nights) {
            return pricing.nights.map((night, nightIndex) => {
              const isWeekend = [0, 6].includes(new Date(night.date).getDay());
              return {
                id: pricingIndex * 100 + nightIndex + 1,
                resourceId: pricing.id,
                name: night.price ?? pricing.value,
                startDate: night.date,
                duration: 1,
                durationUnit: "day",
                timeRangeColor: isWeekend ? "orange" : "green",
              };
            });
          } else {
            return [];
          }
        }
      );
      return resourceTimeRanges;
    },
  },

  methods: {
    emitDateRangeChanged() {
      this.$emit("dateRangeChanged", [this.startDate, this.endDate]);
    },
    debouncedEmitDateRangeChanged: debounce(function () {
      this.emitDateRangeChanged();
    }, 100),

    goTo(number) {
      this.startDate = DateHelper.add(this.startDate, number, "days");
      this.endDate = DateHelper.add(this.endDate, number, "days");
      this.renderKey++;
    },

    today() {
      const startDate = DateHelper.add(new Date(), -5, "days");
      const endDate = DateHelper.add(new Date(), 30, "days");

      if (this.startDate > startDate) {
        this.startDate = startDate;
        this.$nextTick(function () {
          this.endDate = endDate;
        });
      } else {
        this.endDate = endDate;
        this.$nextTick(function () {
          this.startDate = startDate;
        });
      }

      this.renderKey++;
    },
  },
};
</script>

<style lang="scss">
@import "~@bryntum/scheduler/scheduler.stockholm.css";

.clean,
.dirty {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 9999px;
}
.b-timeline-subgrid .shaded {
  border-inline-end: 2px solid red;
  background-color: transparent;
}
.b-sch-range.shaded {
  background-color: transparent;
}
.clean {
  background-color: #e81212;
}

.dirty {
  background-color: #72e812;
}
</style>
