<template>
  <div>
    <div class="page-wrapper-table booking-table">
      <div class="page-wrapper-table-header">
        <h2>{{ $t("PRICING_RULES.SELECT_A_TIME_INTERVAL") }}</h2>
        <div class="booking-search-form">
          <base-input class="search-dates">
            <el-date-picker
              v-model="selectedDateRange"
              type="daterange"
              start-placeholder="Date de début"
              end-placeholder="Date de fin"
              format="dd MMM yyyy"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </base-input>
        </div>
      </div>
      <div
        :class="
          'page-wrapper-table-body ' +
          (filterReduced ? 'page-wrapper-table-body--folded' : '')
        "
      >
        <pricing-calendar-component
          :startDateValue="selectedDateRange[0]"
          :endDateValue="selectedDateRange[1]"
          :pricingRules="pricingRules"
          @dateRangeChanged="
            (dateRange) => {
              selectedDateRange = dateRange;
            }
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import fr from "element-ui/lib/locale/lang/fr";
import locale from "element-ui/lib/locale";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Input,
  InputNumber,
  Option,
  Radio,
  RadioGroup,
  Row,
  Select,
  Slider,
  Table,
  TableColumn,
  Tooltip,
  Collapse,
  CollapseItem,
  Switch,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import PricingCalendarComponent from "@/components/PricingCalendarComponent.vue";
import moment from "moment";

locale.use(fr);

export default {
  name: "calendar-table",

  components: {
    PricingCalendarComponent,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Slider.name]: Slider,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Input.name]: Input,
    [InputNumber.name]: InputNumber,
    [Row.name]: Row,
    [Col.name]: Col,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Switch.name]: Switch,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      selectedDateRange: [
        moment().format("YYYY-MM-DD"),
        moment().add(30, "days").format("YYYY-MM-DD"),
      ],
      pricingRules: [],
      loading: true,
      filterReduced: false,
    };
  },

  created() {
    this.getList();
  },

  watch: {
    selectedDateRange: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 500),

    async getList() {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {},
          page: {
            number: 1,
            size: 1000,
          },
          include: "derived",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }

        if (this.selectedDateRange) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              date_range: this.selectedDateRange.map((date) =>
                moment(date).toISOString()
              ),
            },
          };
        }

        await this.$store.dispatch("pricingRules/list", params);
        this.pricingRules = this.$store.getters["pricingRules/list"];
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>

<style>
.gap-16px {
  gap: 16px;
}
</style>
